/* eslint-disable no-console, no-undef */

import {init, configureScope, captureMessage, captureException} from '@sentry/browser';

window.Sentry = {
    environment: window.SentryEnvironment,
    release: window.SentryRelease,
};

if (process.env.NODE_ENV === 'production') {
    init({...Sentry, dsn: 'https://141cc8b027ce47449edd0229c289c287@sentry.cosmocode.de/25'});

    const user = window.SentryUserData ? {...SentryUserData} : null;

    if (user) configureScope(scope => scope.setUser(user));

    Sentry.message = captureMessage;
    Sentry.exception = captureException;
} else {
    Sentry.message = message => console.log(message);
    Sentry.exception = exception => console.error(exception);
}
